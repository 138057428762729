import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import axios from 'axios';
export const AccountActivation=()=>{
    let { userId,token } = useParams();
    const [opMsg,setOpMsg]=useState("");
    const [username,setUsername]=useState("");
    const [isLoading,setIsLoading]=useState(true);
    useEffect(()=>{
        console.log('called');
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/activate/${userId}/${token}`)
        .then((response)=>{
            setIsLoading(false);
            if(response.data.status===200){
                setUsername(response.data.data.username);
                setOpMsg(response.data.opMsg);
            }
            else{
                setOpMsg(response.data.message);
            }
          
        })
      },[])
      
    if(isLoading){
        return (
           <div style={{fontSize:30,backgroundColor:'grey'}}>Loading...</div>
        )
    }
    return(
        <>
           <h1>Account Activation {username===""?null:"for User " +username} </h1>
            <p><i>{opMsg}</i></p>
        </>
    )
}