import React, { useContext,useEffect,useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { UserContext } from '../App';
import axios from 'axios';
import swal from 'sweetalert';
import './Profile.css';
import * as imageConversion from 'image-conversion';

export const Profile=()=>{
  const [isUploadingImg,setIsUploadingImg]=useState(false);
  const [isUpdating,setIsUpdating]=useState(false);
    const navigate=useNavigate();
    const [images, setImages] = useState({
        icon: 'anonymous.png',
        front: 'anonymous_card.png',
        back: 'anonymous_card.png'
      });
    const [isImgUpdated,setIsImgUpdated]=useState({
        icon:false,
        front:false,
        back:false
    })

    const [fileInputValue,setFileInputValue]=useState({//this state is set for displaying filename after receiving fileinput
      front:"",
      icon:'',
      back:''
    })

      //when images or isImgUpdated update, formdata update
      useEffect(()=>{
        console.log('updating formdata due to images/isImgUpdated change')
        //console.log(images);
        //console.log(isImgUpdated);
        setFormData({
            ...formData,
            images:images,
            isImgUpdated:isImgUpdated
        })
        
      },[images,isImgUpdated]);

      //when isImgUpdated update, formdata update
      // useEffect(()=>{
      //   console.log('updating formdata due to isImgUpdated change')
      //   console.log(isImgUpdated);
      //   // setFormData({
      //   //     ...formData,
      //   //     isImgUpdated:isImgUpdated
      //   // })
              
      // },[isImgUpdated]);

      useEffect(()=>{
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/${localStorage.getItem("username")}`)
        .then((response)=>{
            //console.log(response.data.data)
            let resObj=response.data.data
            setFormData({
                ...formData,
                firstName:resObj.firstName,
                lastName: resObj.lastName,
                organization: resObj.organization,
                title: resObj.title,
                phoneNo: resObj.phoneNo,
                phoneNoCode:resObj.phoneNoCode,
                phoneNo2: resObj.phoneNo2,
                phoneNoCode2:resObj.phoneNoCode2,
                website: resObj.website,
                website2: resObj.website2,
                website3: resObj.website3,
                website4: resObj.website4,
                email: resObj.email,
                emailonprofile:resObj.emailonprofile,
                address: resObj.address,
                note:resObj.note
            })
            setImages(
                {
                    icon:resObj.imageIconBase64?"data:image/png;base64,"+resObj.imageIconBase64:'anonymous.png',
                    front:resObj.imageFrontBase64?"data:image/png;base64,"+resObj.imageFrontBase64:'anonymous_card.png',
                    back:resObj.imageBackBase64?"data:image/png;base64,"+resObj.imageBackBase64:'anonymous_card.png',
                }
            )
            
        })
      },[])

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        organization: '',
        title: '',
        phoneNo: '',
        phoneNoCode:'',
        phoneNo2:'',
        phoneNoCode2:'',
        website: '',
        website2: '',
        website3: '',
        website4: '',
        email: '',
        emailonprofile:'',
        address: '',
        note: '',
        images:images,
        isImgUpdated:isImgUpdated
      });


      useEffect(()=>{
        console.log('formdata updated to');
        //console.log(formData);
      },[formData]);

    // const [userContext,setUserContext] = useContext(UserContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };
    const handleSubmit = (e) => {
        e.preventDefault();
      
        //Make sure both are not empty when one of Code/Number is not empty
        if(formData.phoneNo2!=="" && formData.phoneNoCode2===""){
              //alert("Please filled in Country code for  Phone Number(Mobile) OR remove Phone number for  Phone Number(Mobile) ");
              swal({
                title:"Phone Number (Mobile) NOT synchronized",
                text:"Please filled in Country code for  Phone Number(Mobile) OR remove Phone number for  Phone Number(Mobile) ",
                icon: "warning",
                closeOnClickOutside:false
              })
        
              return;
        }
        else if(formData.phoneNoCode2!=="" && formData.phoneNo2===""){
              //alert("Please filled in Phone Number(Mobile) OR remove Country code for  Phone Number(Mobile)");
              swal({
                title:"Phone Number (Mobile) NOT synchronized",
                text:"Please filled in Phone Number(Mobile) OR remove Country code for Phone Number(Mobile)",
                icon: "warning",
                closeOnClickOutside:false
              })
              return;
        }

        console.log('submitting');
      // Handle form submission logic here, e.g., sending data to server
        setIsUpdating(true);
        console.log(formData); 
        //console.log(userContext.username);
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/users/${localStorage.getItem("username")}/update`,formData,{headers:{'token':localStorage.getItem("token")}})
        .then((result)=>{
            console.log(result.data)
            //alert(result.data.opMsg            )  ; 
            swal({
              text:result.data.opMsg,
              icon: "success",
              closeOnClickOutside:false
            })
            .then((ok)=>{
              navigate(`/card/${localStorage.getItem("username")}`);
            })
            
        })
        .catch((err)=>{
            //alert(`Some error happen: ${err}`)  ;  
            swal({
              title:' The profile is not saved. ',
              text:`Some error happen: ${err} \n 
                
              `,
              // If you upload an image, there maybe chance that the file is too large. We only accept images under 10 mb. Sorry for inconvenience.
              icon: "error",
              closeOnClickOutside:false
            })
        })
        .finally(()=>{
          setIsUpdating(false);
        })
    
    };


      // Function to handle file input changes
      const handleFileInputChange = (e, imageKey) => {
        if(imageKey==='icon'){
          setIsImgUpdated({...isImgUpdated,icon:true});
          setFileInputValue({
            ...fileInputValue,
            icon:e.target.value.split(/(\\|\/)/g).pop()
          })
        }
        else if(imageKey==='front'){
          setIsImgUpdated({...isImgUpdated,front:true});
          setFileInputValue({
            ...fileInputValue,
            front:e.target.value.split(/(\\|\/)/g).pop()
          })
        }
        else if(imageKey==='back'){
          setIsImgUpdated({...isImgUpdated,back:true});
          setFileInputValue({
            ...fileInputValue,
            back:e.target.value.split(/(\\|\/)/g).pop()
          })
        }



        // setFormData({...formData,isImgUpdated:true})
       
        const file = e.target.files[0];
        /***compression */
        setIsUploadingImg(true)
        imageConversion.compressAccurately(file,1000).then(res=>{
          //The res in the promise is a compressed Blob type (which can be treated as a File type) file;
          //first compressed, and then convert to base 64
          setIsUploadingImg(false)
          const reader = new FileReader();

          reader.onloadend = () => {
          // After the file is read, update the state with the base64 image data
          setImages({
              ...images,
              [imageKey]: reader.result
          })
          ;
  
          };
          if (res) {
          // Read the file as a data URL which will give us a base64-encoded image
          reader.readAsDataURL(res);
          }
        })


        /****Compression ends */

     
    };
    const handleFileInputDelete = (e, imageKey) => {
      e.preventDefault();
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this image!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        closeOnClickOutside:false
      })
      .then((chooseDelete) => {
        if (chooseDelete) {
          let path="";
          if(imageKey==='icon'){
            setIsImgUpdated({...isImgUpdated,icon:true});
            path= 'anonymous.png';
            document.getElementById('icon').value=null;
            setFileInputValue({
              ...fileInputValue,
              icon:""
            })
          }
          else if(imageKey==='front'){
            setIsImgUpdated({...isImgUpdated,front:true});
            path='anonymous_card.png';
            document.getElementById('front').value=null;
            setFileInputValue({
              ...fileInputValue,
              front:""
            })
          }
          else if(imageKey==='back'){
            setIsImgUpdated({...isImgUpdated,back:true});
            path='anonymous_card.png';
            document.getElementById('back').value=null;
            setFileInputValue({
              ...fileInputValue,
              back:""
            })
          }
          setImages({
              ...images,
              [imageKey]: path
          });
         // alert("Done. Press Update to save your changes.")
          swal({
            text:"Done. Press Update to save your changes.",
            icon: "info",
            closeOnClickOutside:false
          })

        } else {
        //nth
        }
      });

      // if(window.confirm(`Are you sure ?`)){
      //     let path="";
      //     if(imageKey==='icon'){
      //       setIsImgUpdated({...isImgUpdated,icon:true});
      //       path= 'anonymous.png';
      //       document.getElementById('icon').value=null;
      //     }
      //     else if(imageKey==='front'){
      //       setIsImgUpdated({...isImgUpdated,front:true});
      //       path='anonymous_card.png';
      //       document.getElementById('front').value=null;
      //     }
      //     else if(imageKey==='back'){
      //       setIsImgUpdated({...isImgUpdated,back:true});
      //       path='anonymous_card.png';
      //       document.getElementById('back').value=null;
      //     }
      //     setImages({
      //         ...images,
      //         [imageKey]: path
      //     });
      //    // alert("Done. Press Update to save your changes.")
      //     swal({
      //       text:"Done. Press Update to save your changes.",
      //       icon: "info",
      //       closeOnClickOutside:false
      //     })
      // }
     
  };

  if(isUploadingImg){
      return (
        <div style={{fontSize:30,backgroundColor:'grey'}}>Uploading Image... Please Wait...</div>
      )
  }

    if(isUpdating){
      return (
         <div style={{fontSize:30,backgroundColor:'grey'}}>Updating...</div>
      )
   }

    return(
        <>
        {/* <h1>Home Page</h1> */}
        
        {localStorage.getItem("username")?
        <>
        <h1>Edit Profile</h1>
        
            <hr/>
        <p style={{fontWeight:'bold'}}>Your card is avaliable at <Link to={`/card/${localStorage.getItem("username")}`}>{window.location.hostname+`/#/card/${localStorage.getItem("username")}`} </Link> </p><hr/>
    
    
        <p>---📝You can edit your profile below---</p><hr/>
        
        <form onSubmit={handleSubmit} >
        
        <div className='profileInputBlock' style={{marginTop:0}}>
            <label className='profile-label' htmlFor="icon">Icon:</label>
            <img id='iconimg' className='profileInputImg' src={images.icon} width="100" />
            <button className='choose-file-button' onClick={(e)=>{e.preventDefault();document.getElementById('icon').click()}} >Choose a file ... </button>
            <p className='upload-max-reminder'>[Max: 10 MB]</p>
            <span className='upload-filename'>{fileInputValue.icon?"You have chosen: "+fileInputValue.icon:"no file chosen"}</span>
            <input
            type="file"
            id="icon"
            name="icon"
            accept="image/*"
            onChange={(e) => handleFileInputChange(e, 'icon')}
            style={{display:"none"}}
            />
            {images.icon!=="anonymous.png"? <button className='delete-file-button' onClick={(e) => handleFileInputDelete(e, 'icon')}>Delete</button>:null}
        </div>
       
        
        <div className='profileInputBlock' >
            <label className='profile-label' htmlFor="front">Paper Card Frontside:</label>
            <img id='frontimg' className='profileInputImg' src={images.front} width="200" />
            <button className='choose-file-button' onClick={(e)=>{e.preventDefault();document.getElementById('front').click()}} >Choose a file ... </button>
            <p className='upload-max-reminder'>[Max: 10 MB]</p>
            <span className='upload-filename'>{fileInputValue.front?"You have chosen: "+fileInputValue.front:"no file chosen"}</span>
            <input
            type="file"
            id="front"
            name="front"
             accept="image/*"
            onChange={(e) => handleFileInputChange(e, 'front')}
            style={{display:"none"}}
            />
         {images.front!=="anonymous_card.png"?<button className='delete-file-button'  onClick={(e)=>handleFileInputDelete(e,'front')}>Delete</button>:null}      
        </div>
       
        <div className='profileInputBlock' style={{marginTop:0}}>
            <label className='profile-label' htmlFor="back">Paper Card Backside:</label>
            <img id='backimg' className='profileInputImg' src={images.back} width="200" />
            <button className='choose-file-button' onClick={(e)=>{e.preventDefault();document.getElementById('back').click()}} >Choose a file ... </button>
            <p className='upload-max-reminder'>[Max: 10 MB]</p>
            <span className='upload-filename'>{fileInputValue.back?"You have chosen: "+fileInputValue.back:"no file chosen"}</span>
            <input
            type="file"
            id="back"
            name="back"
             accept="image/*"
            onChange={(e) => handleFileInputChange(e, 'back')}
            style={{display:"none"}}
            />
             {images.back!=="anonymous_card.png"?<button className='delete-file-button'  onClick={(e) => handleFileInputDelete(e, 'back')}>Delete</button>:null}
        </div>
        
      <div className='profileInputBlock'>
        <label  htmlFor="firstName" className='profile-label'>Your Name:<span style={{color:'red'}}>(*required)</span></label>
        <input
          
          type="text"
          id="firstName"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          placeholder="First Name"
          required
          onInvalid={e => e.target.setCustomValidity('Please fill in this field')} onInput={e => e.target.setCustomValidity('')}
        />  
        <input
    
          type="text"
          id="lastName"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          placeholder='Last Name'
          required
          onInvalid={e => e.target.setCustomValidity('Please fill in this field')} onInput={e => e.target.setCustomValidity('')}
        />
      </div>
 
      <div className='profileInputBlock'>
        <label  htmlFor="organization" className='profile-label'>Occupation:</label>
        <input
          
          type="text"
          id="organization"
          name="organization"
          value={formData.organization}
          onChange={handleChange}
          placeholder="Organization"
        /> 
         <input
          
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          placeholder="Title"
        />
      </div >
      {/* <div  className='profileInputBlock'>
        <label  htmlFor="title" className='profile-label'>Title:</label>
      
      </div> */}
      <div className='profileInputBlock'>
        <label  htmlFor="phoneNo" className='profile-label'>Phone Number (Work):<span style={{color:'red'}}>(*required)</span></label>
       
        <span>Country Code (eg. 852) </span>
        
        <input 
          
          type="tel"
          id="phoneNoCode"
          name="phoneNoCode"
          value={formData.phoneNoCode}
          onChange={handleChange}
          placeholder="852"
          
          required
          onInvalid={e => e.target.setCustomValidity('Please fill in this field')} onInput={e => e.target.setCustomValidity('')}
        />
       
        
       <span>Number  </span>
     
        <input
          
          type="tel"
          id="phoneNo"
          name="phoneNo"
          value={formData.phoneNo}
          onChange={handleChange}
          placeholder="92345678"
        
          required
          onInvalid={e => e.target.setCustomValidity('Please fill in this field')} onInput={e => e.target.setCustomValidity('')}
        />
      
        
      </div>
      <div className='profileInputBlock'>
        <label  htmlFor="phoneNo2" className='profile-label'>Phone Number (Mobile):</label>
        <span>Country Code (eg. 852) </span>
        
        <input 
          
          type="tel"
          id="phoneNoCode2"
          name="phoneNoCode2"
          value={formData.phoneNoCode2}
          onChange={handleChange}
          placeholder="852"
  
        />
       
        
       <span>Number  </span>
        <input
          
          type="tel"
          id="phoneNo2"
          name="phoneNo2"
          value={formData.phoneNo2}
          onChange={handleChange}
          placeholder="22345678"
     
          
        />
      </div>
      <div className='profileInputBlock'>
        <label  htmlFor="emailonprofile" className='profile-label'>Email:</label>
            <input
            type="email"
            id="emailonprofile"
            name="emailonprofile"
            value={formData.emailonprofile}
            onChange={handleChange}
            placeholder='abc@gmail.com'
            onInvalid={e => e.target.setCustomValidity('Please enter valid email')} onInput={e => e.target.setCustomValidity('')}
            />
     
      </div> 
      <div className='profileInputBlock'>
        <label  htmlFor="website" className='profile-label'>Website/ Social Media Link : </label>
        <input
          
          type="url"
          id="website"
          name="website"
          value={formData.website}
          onChange={handleChange}
          placeholder="http://example.com"
          autoCorrect={false}
          autoCapitalize={false}
          onInvalid={e => e.target.setCustomValidity('Please enter valid url. Format: http://example.com')} onInput={e => e.target.setCustomValidity('')}
        />
      {/* </div>
      <div className='profileInputBlock'>
        <label  htmlFor="website2" className='profile-label'>Website 2: </label> */}
        <input
          
          type="url"
          id="website2"
          name="website2"
          value={formData.website2}
          onChange={handleChange}
          placeholder="http://example.com"
          autoCorrect={false}
          autoCapitalize={false}
          onInvalid={e => e.target.setCustomValidity('Please enter valid url. Format: http://example.com')} onInput={e => e.target.setCustomValidity('')}
        />
      {/* </div>
      <div className='profileInputBlock'>
        <label  htmlFor="website3" className='profile-label'>Website 3: </label> */}
        <input
          
          type="url"
          id="website3"
          name="website3"
          value={formData.website3}
          onChange={handleChange}
          placeholder="http://example.com"
          autoCorrect={false}
          autoCapitalize={false}
          onInvalid={e => e.target.setCustomValidity('Please enter valid url. Format: http://example.com')} onInput={e => e.target.setCustomValidity('')}
        />
      {/* </div>
      <div className='profileInputBlock'>
        <label  htmlFor="website4" className='profile-label'>Website 4: </label> */}
        <input
          
          type="url"
          id="website4"
          name="website4"
          value={formData.website4}
          onChange={handleChange}
          placeholder="http://example.com"
          autoCorrect={false}
          autoCapitalize={false}
          onInvalid={e => e.target.setCustomValidity('Please enter valid url. Format: http://example.com')} onInput={e => e.target.setCustomValidity('')}
        />
      </div>
     
      <div className='profileInputBlock'>
        <label  htmlFor="address" className='profile-label'>Address:</label>
        <textarea
          id="address"
          name="address"
          value={formData.address}
          onChange={handleChange}
        />
      </div>
      <div className='profileInputBlock'>
        <label  htmlFor="note" className='profile-label'>Note:</label>
        <textarea
          id="note"
          name="note"
          value={formData.note}
          onChange={handleChange}
        />
      </div>
      {/* <div className='profileInputBlock'>
        <label  htmlFor="email" className='profile-label'>Email:</label>
            <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder='abc@gmail.com'
            disabled={true}
            />
     
      </div> */}
      <button
        type="submit"  disabled={isUpdating}
      >
        {isUpdating?"Updating...":"Update Your Card"}
        
      </button>
    </form>
        </>:
        <>
        <h1>Welcome Visitor!</h1>
        <p>Please <Link to="/login">login</Link> to continue</p>
        </>}
        </>
    )
}