
import './App.css';
import {HashRouter ,Routes ,Route } from 'react-router-dom';
import {Home} from './pages/Home';
import { Nav } from './components/Nav';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { createContext, useEffect, useState } from 'react';
import { UserCard } from './pages/UserCard';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import { Profile } from './pages/Profile';
import { ChangePassword } from './pages/ChangePassword';
import { AccountActivation } from './pages/AccountActivation';
import { ForgetPassword } from './pages/ForgetPassword';
import { ResetPassword } from './pages/ResetPassword';
import { Contact } from './pages/Contact';
import { NotFound } from './pages/NotFound';


// export const UserContext=createContext();
function App() {
  //const [userContext,setUserContext]=useState({username:"",token:""})

  return (
    <div className="App">
      {/* <UserContext.Provider value={[userContext,setUserContext]}> */}
      <HashRouter>
        <Header title="NFC Workshop Limited" subTitle="Digital Business Card"  />
       
        <Routes>
          <Route path="/" element={<Home/>}/> 
          
          <Route path="/login" element={<Login/>}/> 
          <Route path="/register" element={<Register/>}/> 
          <Route path="/forgetpassword" element={<ForgetPassword/>}/>
          
          <Route path="/card/:username" element={<UserCard/>}/>
          <Route path="/activate/:userId/:token" element={<AccountActivation/>}/>
          <Route path="/resetpassword/:userId/:token" element={<ResetPassword/>}/>
          <Route path="/contact" element={<Contact/>}/>
          { localStorage.getItem("username")? 
          <>
          <Route path="/profile" element={<Profile/>}/> 
          <Route path="/changepassword" element={<ChangePassword/>}/>
          </>
        :null } 
          <Route path='/*' element={<NotFound />} />
        </Routes>
        <Footer note="Copyright © 2024  NFC Workshop Limited | All Rights Reserved." />
      </HashRouter>
 
      

      {/* </UserContext.Provider> */}
    </div>
  );
}

export default App;
