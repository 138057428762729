// import { UserContext } from "../App";
import  { useContext } from 'react';
import { Link ,useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form"
import * as yup from 'yup'
import axios from "axios"
import {yupResolver} from '@hookform/resolvers/yup'
import swal from "sweetalert";
export const ChangePassword=()=>{
    // const [userContext,setUserContext] = useContext(UserContext);
    const navigate  =useNavigate();
    const schema = yup.object().shape({
    
        oldPassword: yup.string().min(8,"Old Password should be at least 8 characters").max(20).required(),
        newPassword:yup.string().min(8,"New Password should be at least 8 characters").max(20).required(),
        confirmedPassword:yup.string().oneOf([yup.ref("newPassword")],"pw not matched!").required()
    })

    const {register,handleSubmit ,formState:{errors}}=useForm({
        resolver:yupResolver(schema)
    });

    const logoutHandler=()=>{
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/logout`,{headers:{'token':localStorage.getItem("token")}})
        .then((response)=>{            
            // Cookies.set('token',"");
            //setUserContext({username:"",token:""});
            localStorage.setItem("username","");
            localStorage.setItem("token","");
            localStorage.setItem("email","");
            navigate("/");
            window.location.reload();


        });
    }
    const mySubmitHandler=(data)=>{
        console.log(data);// {oldPassword:..., newPassword:...,confirmedPassword:...}
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/changepassword`,data,{headers:{'token':localStorage.getItem("token")}})
        .then((response)=>{
            if(response.data.status==200){

                //alert(response.data.opMsg);
                //alert("You will be automatically logout.")
                swal({
                    text: response.data.opMsg,
                    icon: "success",
                    button: "OK",
                    closeOnClickOutside:false
                })
                .then((ok)=>{
                    return swal({
                        text: "You will be automatically logout.",
                        button: "OK",
                        closeOnClickOutside:false
                    })
                })
                .then((ok)=>{
                    logoutHandler(); 
                })

                
               
               
            }else{
                swal({
                    text: response.data.message,
                    icon: "error",
                    button: "OK",
                    closeOnClickOutside:false
                })
                //alert(response.data.message);
            }
        })
        .catch((err)=>{
            //alert(`Some error happen: ${err}`)  ;  
            swal({
                text: `Some error happen: ${err}`,
                icon: "error",
                button: "OK",
                closeOnClickOutside:false
            })
        })
    }


    return(
        <>
        {localStorage.getItem("username")?
        <>
        <h1>Change Your Password</h1>
        <hr/>
        <form onSubmit={handleSubmit(mySubmitHandler)}>

            <label className='login-label'>Old Password:</label>
            <input type="password" placeholder="Old Password here..."  {...register("oldPassword")}/>
            <p style={{color:"red"}}>{errors.oldPassword?.message}</p>

            <label className='login-label'>New Password: (at least 8 characters)</label>
            <input type="password" placeholder="New Password here..." {...register("newPassword")}/>
            <p style={{color:"red"}}>{errors.newPassword?.message}</p>
            <label className='login-label'>Confirmed New Password:</label>
            <input type="password" placeholder="Confirmed Password here..." {...register("confirmedPassword")}/>
            <p style={{color:"red"}}>{errors.confirmedPassword?.message}</p>

            <input type="submit" value="Update Password" style={{marginBottom:"20px"}}/>

        </form>
    

        </>:
        <>
        <h1>Welcome Visitor!</h1>
        <p>Please <Link to="/login">login</Link> to continue</p>
        </>}
        </>
    )
}