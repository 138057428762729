import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import swal from "sweetalert";
export const ForgetPassword=()=>{
    
    const [username,setUsername]=useState("");
    const [isLoading,setIsLoading]=useState(false);
    const navigate  =useNavigate();
    const mySubmitHandler=(event)=>{
        event.preventDefault();
        setIsLoading(true);
        let data={
            username
        };
        console.log(data);
        if(username===""){
            setIsLoading(false);
            swal({
                text:"Failed:\n"+"Please fill in username or email",
                icon: "error",
                closeOnClickOutside:false
            })
        }
        else{
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/forgetpassword`,data)
            .then((response)=>{
                setIsLoading(false);
                if(response.data.status===200){
                    
                // alert(response.data.opMsg);
                    swal({
                        text: response.data.opMsg,
                        icon: "success",
                        closeOnClickOutside:false
                    })
                    .then((ok)=>{
                        setUsername("");
                        navigate("/login");
                    })
                    
                    
                }else{
                    // alert("Error: "+response.data.message);
                    swal({
                        text:"Error: "+response.data.message,
                        icon: "error",
                        closeOnClickOutside:false
                    })
                
                }
            })
            .catch((err)=>{
                //alert(`Some error happen: ${err}`)  ;  
                setIsLoading(false);
                swal({
                    text:`Some error happen: ${err}`,
                    icon: "error",
                    closeOnClickOutside:false
                })
            })
        }

    }
    if(isLoading){
        return (
           <div style={{fontSize:30,backgroundColor:'grey'}}>Loading...</div>
        )
     }
    return(
        <>
           <h1>Forget Password </h1>
           <p>If you forget your password, you can complete the form below, a password reset email would be sent to your email address.</p>
            <form onSubmit={(e)=>{mySubmitHandler(e)}}>
            <div style={{marginTop:"20px"}}>
                <label htmlFor="username" className='login-label'>Username/Email</label>
                <input id="username" type="text" placeholder="Username or Email" onChange={(e)=>{setUsername(e.target.value)}}  value={username}/>
                <p></p>
            </div>
            <input type="submit" value="Confirm" style={{marginBottom:"20px"}}/>
        </form>
        </>
    )
}