import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import './Contact.css';
import swal from "sweetalert";
export const Contact=()=>{
    const navigate  =useNavigate();
    const [fullName,setFullName]=useState("");
    const [email,setEmail]=useState("");
    const [mobile,setMobile]=useState("");
    const [subject,setSubject]=useState("");
    const [msg,setMsg]=useState("");
    const [isLoading,setIsLoading]=useState(false);
    const mySubmitHandler=(event)=>{
        event.preventDefault();
        setIsLoading(true);
        let data={
            fullName,
            email,
            mobile,
            subject,
            msg
        };
        console.log(data);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/contact`,data)
        .then((response)=>{
            
            if(response.data.status===200){
                navigate("/");
                //alert(response.data.opMsg);
                swal({
                    text: response.data.opMsg,
                    icon: "success",
                    closeOnClickOutside:false
                });
            }else{
               // alert("Failed sending email\n"+response.data.message);
                swal({
                    text: "Failed sending email\n"+response.data.message,
                    icon: "error",
                    closeOnClickOutside:false
                });
            }
            
        })
        .catch((err)=>{
            //alert(`Some error happen: ${err}`)  ; 
            swal({
                text: `Some error happen: ${err}`,
                icon: "error",
                closeOnClickOutside:false
            });
        })
        .finally(()=>{
            setIsLoading(false);
        })
        

    }
    if(isLoading){
        return (
           <div style={{fontSize:30,backgroundColor:'grey'}}>Loading...</div>
        )
     }
    return(
        <>
        <h1>Contact Us</h1> 
        <form onSubmit={(e)=>{mySubmitHandler(e)}}>
            <div style={{marginTop:"20px"}}>
                <label htmlFor="fullName" className="contact-label">Full Name</label>
                <input id="fullName" className="contact-us-field" type="text" placeholder="Fullname here" onChange={(e)=>{setFullName(e.target.value)}}  value={fullName} required onInvalid={e => e.target.setCustomValidity('Please fill in this field')} onInput={e => e.target.setCustomValidity('')}/>
                
            </div>

            <div>
                <label htmlFor="email" className="contact-label">Email</label>
                <input id="email" className="contact-us-field" type="email" placeholder="Email here"  onChange={(e)=>{setEmail(e.target.value)}} value={email} required onInvalid={e => e.target.setCustomValidity('Please enter a valid email')} onInput={e => e.target.setCustomValidity('')}/>
              
            </div>
            <div>
                <label htmlFor="mobile" className="contact-label">Mobile Number</label>
                <input id="mobile" className="contact-us-field" type="tel" placeholder="Mobile Number here"  onChange={(e)=>{setMobile(e.target.value)}} value={mobile} required  onInvalid={e => e.target.setCustomValidity('Please fill in this field')} onInput={e => e.target.setCustomValidity('')}/>
              
            </div>
            <div>
                <label htmlFor="subject" className="contact-label">Email Subject</label>
                <input id="subject" className="contact-us-field" type="text" placeholder="Subject here"  onChange={(e)=>{setSubject(e.target.value)}} value={subject} required  onInvalid={e => e.target.setCustomValidity('Please fill in this field')} onInput={e => e.target.setCustomValidity('')}/>
              
            </div>
            <div>
                <label htmlFor="msg" className="contact-label">Message</label>
                <textarea id="msg" className="contact-us-field"  placeholder="Message here"  onChange={(e)=>{setMsg(e.target.value)}}  value={msg} required  onInvalid={e => e.target.setCustomValidity('Please fill in this field')} onInput={e => e.target.setCustomValidity('')}/>
              
            </div>
            
            <input type="submit" value="Submit" style={{marginBottom:"20px"}}/>
        </form>

        </>
    )

}