import React, { useEffect, useState } from "react";
import "./Footer.css";
import { FaFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer(props) {
  // const handleOpenFacebook = () => {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  //   const fbPageId = '61555029445312';

  //   const openFacebookApp = (appUrl) => {
  //     window.location.href = appUrl;
  //     // Set a timeout to fallback in case the app is not installed
  //     setTimeout(() => {
  //       window.location.href = 'https://www.facebook.com/nfcworkshop';
  //     }, 1000); // Adjust timeout if necessary
  //   };

  //   if (/android/i.test(userAgent)) {
  //     // Android device
  //     openFacebookApp(`fb://page/${fbPageId}`);
  //   } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //     // iOS device
  //     openFacebookApp(`fb://profile/${fbPageId}`);
  //   } else {
  //     // Desktop or other platforms
  //     window.location.href = 'https://www.facebook.com/nfcworkshop';
  //   }
  // };
  // const handleOpenLi = () => {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  //   const openLiApp = (appUrl) => {
  //     window.location.href = appUrl;
  //     // Set a timeout to fallback in case the app is not installed
  //     setTimeout(() => {
  //       window.location.href = 'https://www.linkedin.com/company/nfc-workshop-limited/';
  //     }, 1000); // Adjust timeout if necessary
  //   };

  //   if (/android/i.test(userAgent)) {      // Android device     
  //     openLiApp(`linkedin://company/nfc-workshop-limited/`);
  //   } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) { // iOS device
  //     openLiApp(`linkedin://company/nfc-workshop-limited/`);
  //   } else {
  //     // Desktop or other platforms
  //     window.location.href = 'https://www.linkedin.com/company/nfc-workshop-limited/';
  //   }
  // };
  return (
    <div className="footer"> 

      <Link to="/contact" ><p>Need help? Contact Us</p></Link>
      <br/> 
      <a   href="https://www.facebook.com/nfcworkshop" className="footer-social-icon"><FaFacebook size={25} className="social-logo" /></a> 
      <a  href='https://www.linkedin.com/company/nfc-workshop-limited/'  className="footer-social-icon"><FaLinkedin size={25} className="social-logo"/> </a> 
      <br/><br/>
      <p>{props.note}</p>
      <br/>
      <p id="designed-by">Designed & Developed by  <a href="http://ivanenergy.github.io" className="atag-by"><span className="designer-name">IVAN CHAN TSZ FUNG</span></a></p>
      <p id="powered-by">Powered by <a href="https://react.dev/"  className="atag-by"><span className="tech-name">React v18.3.1</span></a></p>
    </div>
  );
}
