import React from "react";
import { Nav } from "../Nav";
import "./Header.css";
import { Link } from "react-router-dom";

export default function Header(props) {
  return (
    <div className="header">
      <Link to="/" className="appTitle"><h1 className="appTitleTxt">{props.title} </h1></Link>
      <Link to="/" className="appSubTitle"><h3 className="appSubTitleTxt">{props.subTitle} </h3></Link>
      {/* <Link to="/"></Link> */}
      <div>
        <Nav/>
      </div>
      
    </div>
  );
}
