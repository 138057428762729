import  { useEffect, useState ,useContext} from 'react';
import { FaDownload ,FaPhone} from "react-icons/fa";
import { TfiEmail } from "react-icons/tfi";
import './UserCard.css';
import { useParams ,Link} from 'react-router-dom';
import { saveAs } from 'file-saver';
import axios from 'axios';
// import { UserContext } from '../App';
import { TbWorldWww } from "react-icons/tb";
import { FaHome } from "react-icons/fa";
import { CiStickyNote } from "react-icons/ci";
import { ImBubble } from "react-icons/im";
import swal from 'sweetalert';
export const UserCard=()=>{
    const [isLoading,setIsLoading]=useState(true);
    const [isError,setIsError]=useState(false);
    // const [userContext,setUserContext] = useContext(UserContext);
    const [images, setImages] = useState({
        icon: 'anonymous.png',
        front: 'anonymous_card.png',
        back: 'anonymous_card.png'
      });
      const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        organization: '',
        title: '',
        phoneNoCode:'',
        phoneNo: '',
        phoneNoCode2:'',
        phoneNo2: '',
        website: '',
        website2: '',
        website3: '',
        website4: '',
        email: '',
        emailonprofile:'',
        address: '',
        note: '',
        images:images
      });
    let { username } = useParams();
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/${username}`)
        .then((response)=>{
            if(response.data.status===200){
                console.log(response.data.data)
                let resObj=response.data.data
                setFormData({
                    ...formData,
                    firstName:resObj.firstName,
                    lastName: resObj.lastName,
                    organization: resObj.organization,
                    title: resObj.title,
                    phoneNo: resObj.phoneNo,
                    phoneNoCode: resObj.phoneNoCode,
                    phoneNo2: resObj.phoneNo2,
                    phoneNoCode2: resObj.phoneNoCode2,
                    website: resObj.website,
                    website2: resObj.website2,
                    website3: resObj.website3,
                    website4: resObj.website4,
                    email: resObj.email,
                    emailonprofile:resObj.emailonprofile,
                    address: resObj.address,
                    note:resObj.note
                })
                setImages(
                    {
                        icon:resObj.imageIconBase64?resObj.imageIconBase64:'anonymous.png',
                        front:resObj.imageFrontBase64?resObj.imageFrontBase64:'anonymous_card.png',
                        back:resObj.imageBackBase64?resObj.imageBackBase64:'anonymous_card.png',
                    }
                )
            }
            else{
                setIsError(true)
            }
        })
        .catch((err)=>{
            //alert(err);
            swal({
                text:err,
                icon: "error",
                closeOnClickOutside:false
            })
            .then((ok)=>{
                setIsError(true)
            })

            
            
        })
        .finally(()=>{
             setIsLoading(false);
        })
      },[])

      const onSaveHandler = () => {

        // Content of the VCF file
        console.log(images.icon)
        const vcfContent = `BEGIN:VCARD
VERSION:3.0
N:${formData.lastName};${formData.firstName};;;
ORG:${formData.organization?formData.organization:""}
TITLE:${formData.title?formData.title:""}
TEL;TYPE=WORK,VOICE:${'+'+formData.phoneNoCode+'-'+formData.phoneNo}
${formData.phoneNo2&&formData.phoneNoCode2?"TEL;TYPE=CELL,VOICE:"+'+'+formData.phoneNoCode2+'-'+formData.phoneNo2:""}
EMAIL:${formData.emailonprofile?formData.emailonprofile:""}
${formData.address?"ADR;TYPE=WORK,PREF:;;"+formData.address.replace(/\n/g, " "):""}
NOTE:${formData.note?formData.note:""}
URL:${formData.website?formData.website:""}
URL:${formData.website2?formData.website2:""}
URL:${formData.website3?formData.website3:""}
URL:${formData.website4?formData.website4:""}
${images.icon==='anonymous.png'?"":`PHOTO;TYPE=JPEG;ENCODING=b:${images.icon}`}
END:VCARD`;

    // Create a data URI
    const dataURI = `data:text/vcard;charset=utf-8,${encodeURIComponent(vcfContent)}`;

    // Open the data URI in a new window
    window.open(dataURI);
    };


    if(isLoading){
        return (
           <div style={{fontSize:30,backgroundColor:'grey'}}>Loading...</div>
        )
    }
    if(isError){
        return (
            <div style={{fontSize:30,backgroundColor:'grey'}}>Some errors happened</div>
         )
    }

    return(
        <>
        <div className="employee-card">
          
        <img src={images.icon==='anonymous.png'?'anonymous.png':"data:image/png;base64,"+images.icon} alt="Employee Photo" width={200} height={200} />
        <h2>{formData.title?formData.title:""}</h2>
        <h2>{formData.lastName||formData.firstName?formData.firstName+" "+formData.lastName:" First Name + Last Name"}</h2>
        <h2>{formData.organization?formData.organization:""}</h2>
        

        
        { formData.phoneNo?  <div className='attribute-container'><p className='attribute-text'><a href={`tel:+${formData.phoneNoCode}-${formData.phoneNo}`}><FaPhone /> +{formData.phoneNoCode}&#32;{formData.phoneNo} (Work) </a></p> </div> :null}
       


      
        { formData.phoneNo2?  <div className='attribute-container'><p className='attribute-text'><a href={`tel:+${formData.phoneNoCode2}-${formData.phoneNo2}`}><FaPhone />+{formData.phoneNoCode2}&#32;{formData.phoneNo2} (Mobile)</a></p> </div> :null}
      

      
        { formData.emailonprofile? <div className='attribute-container'><p className='attribute-text'><a href={`mailto:${formData.emailonprofile}`}><TfiEmail /> {formData.emailonprofile}</a></p> </div>:null}
     
      
        { formData.website?<div className='attribute-container'><p className='attribute-text'> <a href={`${formData.website}`}><TbWorldWww />{formData.website}</a></p> </div>:null}
      
        
      
        { formData.website2?<div className='attribute-container'><p className='attribute-text'><a href={`${formData.website2}`}><TbWorldWww /> {formData.website2}</a></p> </div>:null}
       
    
        { formData.website3?<div className='attribute-container'><p className='attribute-text'><a href={`${formData.website3}`}> <TbWorldWww /> {formData.website3}</a></p> </div>:null}
   
        { formData.website4?<div className='attribute-container'><p className='attribute-text'><a href={`${formData.website4}`}> <TbWorldWww /> {formData.website4}</a></p> </div>:null}
 
 
        { formData.address?<div className='attribute-container'><p className='attribute-text'><a href={`http://maps.google.com/?q=${formData.address}`}><FaHome /> {formData.address}</a></p> </div>:null}
 

        { formData.note?<div className='attribute-container'><p className='attribute-text'> <ImBubble />{formData.note}</p><br/></div>:null}
    
        {  (formData.phoneNo||formData.phoneNo2)?
            <div className='download-container' >
                 <button id='save-contact-btn'  onClick={onSaveHandler} > 
                    <FaDownload className='download-icon' size={50} />
                    <h4>Save my contact</h4>
                 </button> 
            </div>
            :
            <div>
                <p>😔There are no information on this card.</p>
                <p>💡<strong>If you are the card owner</strong>, <Link to="/login">Login</Link> &  <Link to="/profile">Update Profile</Link> to Save this Contact Card</p>

            </div>
      
        }
         
        </div>  
        <img src={images.front==='anonymous_card.png'?'anonymous_card.png':"data:image/png;base64,"+images.front}  className='paper-img'  alt="Paper Card frontside" width={'100%'} /><br></br>
        <img src={images.back==='anonymous_card.png'?'anonymous_card.png':"data:image/png;base64,"+images.back} className='paper-img'  alt="Paper Card backside" width={'100%'}   />
        
        </>
    )
}