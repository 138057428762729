
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Cookies from 'js-cookie';
// import { UserContext } from "../App";
import axios from "axios";
import swal from 'sweetalert';
export const Login=()=>{
    const navigate  =useNavigate();
    const [username,setUsername]=useState("");
    const [password,setPassword]=useState("");
    // const [userContext,setUserContext] = useContext(UserContext);
    const mySubmitHandler=(event)=>{
        event.preventDefault();
        let data={
            username,
            password
        };
        if(username===""||password===""){
            swal({
                text:"Failed login:\n"+"Please fill in empty fields",
                icon: "error",
                closeOnClickOutside:false
            })
        }
        else{
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/login`,data)
            .then((response)=>{
                //console.log(response.data) {authSuccess:..,message:...} 
                if(response.data.authSuccess===true){
                    const token= response.data.token;
                    //Cookies.set('token', token, { expires: 7, secure: true }); //7 is expiration date ; secure ensure cookie only send through https
                    navigate("/");
                    //console.log(response.data);
                    //console.log("token is "+Cookies.get('token'));
                    // setUserContext({username:response.data.username,email:response.data.email,token:token});
                    localStorage.setItem("username",response.data.username);
                    localStorage.setItem("email",response.data.email);
                    localStorage.setItem("token",token);
                    window.location.reload();
                }else{
                   
                    swal({
                        text:"Failed login:\n"+response.data.message,
                        icon: "error",
                        closeOnClickOutside:false
                    })
                    // alert("Failed login\n"+response.data.message);
                }
            })
            .catch((err)=>{
              //  alert(`Some error happen: ${err}`)  ;  
                swal({
                    text:`Some error happen: ${err}`,
                    icon: "error",
                    closeOnClickOutside:false
                })
            })
        }


    }


    if(localStorage.getItem("username")){
       
        return (
            <div style={{fontSize:30,backgroundColor:'grey'}}>You have already Logged in, refresh the page if the app does not load correctly.</div>
         )
    }

    return(
        <>
        <h1>Login</h1> 
        <form onSubmit={(e)=>{mySubmitHandler(e)}}>
            <div style={{marginTop:"20px"}}>
                <label htmlFor="username" className='login-label'>Username/Email</label>
                <input id="username" type="text" placeholder="Username or Email here" onChange={(e)=>{setUsername(e.target.value)}}  value={username}/>
                <p></p>
            </div>

            <div>
                <label htmlFor="password" className='login-label'>Password</label>
                <input id="password" type="password" placeholder="Password here"  onChange={(e)=>{setPassword(e.target.value)}} value={password} />
                <p></p>
            </div>
            
            
            <input type="submit" value="Login now!" style={{marginBottom:"20px"}}/>
        </form>
        <Link to="/forgetpassword" >Forget your password?</Link><br/><br/>
        <Link to="/register">Do not have an account? Register here</Link>
        </>
    )
}