import React, { useContext,useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
// import { UserContext } from '../App';
import axios from 'axios';
export const Home=()=>{



    // const [userContext,setUserContext] = useContext(UserContext);


    return(
        <>
        {/* <h1>Home Page</h1> */}
        
        {localStorage.getItem("username")?
        <>
        <h2>Welcome {localStorage.getItem("username")} !</h2>
        
            <hr/>
        <p style={{fontWeight:'bold'}}>Your card is avaliable at <Link to={`/card/${localStorage.getItem("username")}`}>{window.location.hostname+`/#/card/${localStorage.getItem("username")}`} </Link> </p>
        <hr/>
        <h2 style={{marginTop:10}}>You Can...</h2>
    
        <p>📝Edit your profile <Link to="/profile">HERE </Link> </p>
        <p>🔐Change your login password <Link to="/changepassword">HERE </Link> </p><hr/>
   

        </>:
        <>
        <h2>Welcome Visitor!</h2>
        <p>Please <Link to="/login">login</Link> to continue</p>
        </>}
        </>
    )
}