import { useContext } from 'react';
import { Link} from 'react-router-dom';
// import { UserContext } from '../App';
import axios from 'axios';
// import Cookies from 'js-cookie';
import swal from 'sweetalert';

export const Nav=()=>{
    // const [userContext,setUserContext] = useContext(UserContext);
    const logoutHandler=()=>{
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/logout`,{headers:{'token':localStorage.getItem("token")}})
        .then((response)=>{            
            // Cookies.set('token',"");
            // setUserContext({username:"",email:"",token:""});
            localStorage.setItem("username","");
            localStorage.setItem("email","");
            localStorage.setItem("token","");
            // alert('Logout Success');
            swal({
                text:"Logout Success ",
                icon: "success",
                closeOnClickOutside:false
              })
              .then((ok)=>{
                  
                    window.location.reload();
              })
        });
    }
    return(
    <>
        <Link to="/" className='navLink'>Home</Link>
        {localStorage.getItem("username") ?(
            <>
            <Link to="/profile" className='navLink'>Edit Profile</Link>
            <Link to="/changepassword" className='navLink'>Change Password</Link>
            <Link to="/" onClick={logoutHandler} className='navLink'>Logout </Link>
            
            </>
        )
        :<>
        <Link to="/login" className='navLink'>Login</Link>
        <Link to="/register" className='navLink'>Register</Link>
        </>
        }

    </>
    )
    
}