// import { UserContext } from "../App";
import  { useContext, useEffect, useState } from 'react';
import { Link ,useNavigate, useParams} from 'react-router-dom';
import {useForm} from "react-hook-form"
import * as yup from 'yup'
import axios from "axios"
import {yupResolver} from '@hookform/resolvers/yup'
import swal from "sweetalert";
export const ResetPassword=()=>{
    const navigate  =useNavigate();
    let { userId,token } = useParams();
    const [errMsg,setErrMsg]=useState("");
    const [username,setUsername]=useState("");
    const schema = yup.object().shape({
        newPassword:yup.string().min(8,"New Password should be at least 8 characters").max(20).required(),
        confirmedPassword:yup.string().oneOf([yup.ref("newPassword")],"pw not matched!").required()
    })

    const {register,handleSubmit ,formState:{errors}}=useForm({
        resolver:yupResolver(schema)
    });

    const mySubmitHandler=(data)=>{
        console.log(data);// {newPassword:...,confirmedPassword:...}
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/resetpassword/${userId}/${token}`,data)
        .then((response)=>{
            if(response.data.status==200){
                //alert(response.data.opMsg);
                swal({
                    text:response.data.opMsg,
                    icon: "success",
                    closeOnClickOutside:false
                })
                .then((ok)=>{
                    navigate("/login")
                })
                
            }else{
                setErrMsg("Error: "+response.data.message);
               // alert(response.data.message);
                swal({
                    text:response.data.message,
                    icon: "error",
                    closeOnClickOutside:false
                })
                
            }
        })
        .catch((err)=>{
          //  alert(`Some error happen: ${err}`)  ;  
            swal({
                text:`Some error happen: ${err}`,
                icon: "error",
                closeOnClickOutside:false
            })
        })
    }
    useEffect(()=>{
        
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/resetpassword/${userId}/${token}`)
        .then((response)=>{
            if(response.data.status===200){
                setUsername(response.data.data.username);
            }
            else{
                setErrMsg(response.data.message);
            }
          
        })
      },[])


    return(
        <>
        <h1>Reset Password  {username?"for "+username:""}</h1>
        <hr/>
      
        {errMsg!==""?
        <>
        <p>{errMsg}</p>
        <p>Or you can submit a new password reset request<Link to="/forgetpassword">HERE</Link></p>
        </>
        :<>
        <p>This form would soon be expired.Please submit the form as soon as possible.</p>
        <form onSubmit={handleSubmit(mySubmitHandler)}>

            <label className='login-label'>New Password: (at least 8 characters)</label>
            <input type="password" placeholder="New Password here..."  {...register("newPassword")}/>
            <p style={{color:"red"}}>{errors.newPassword?.message}</p>

            <label className='login-label'>Confirmed New Password:</label>
            <input type="password" placeholder="Confirmed Password here..."  {...register("confirmedPassword")}/>
            <p style={{color:"red"}}>{errors.confirmedPassword?.message}</p>

            <input type="submit" value="Reset Password" style={{marginBottom:"20px"}}/>

        </form>
            </>
        }
        
        </>
    )
}