import { Link, useNavigate } from "react-router-dom"
import {useForm} from "react-hook-form"
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import axios from "axios"
import { useState,useEffect } from "react"
import swal from "sweetalert"


export const Register=()=>{
    const navigate  =useNavigate();
    const [isLoading,setIsLoading]=useState(false);
    const schema = yup.object().shape({
        username:yup.string().matches( /^[a-zA-Z0-9]+$/,
              "This field cannot contain white space and special character").min(4).max(20).required("Username must be entered"),
        email: yup.string().required("Email must be entered"),
        password:yup.string().min(8).max(20).required(),
        confirmedPassword:yup.string().oneOf([yup.ref("password")],"pw not matched!").required()
    })

    const {register,handleSubmit ,formState:{errors}}=useForm({
        resolver:yupResolver(schema)
    });
    const mySubmitHandler=(data)=>{
        //console.log(data); {username:.., email:.., password:...,confirmedPassword:...}
        setIsLoading(true);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/create`,data)
        .then((response)=>{
            setIsLoading(false);
            console.log(`${process.env.REACT_APP_BACKEND_URL}/api/users/create`)
            if(response.data.status==200){
                //alert(response.data.opMsg);
                swal({
                    text:response.data.opMsg,
                    icon: "success",
                    closeOnClickOutside:false
                })
                .then((ok)=>{
                    navigate("/login");
                })
                
            }else{
                //alert("Failed register \n"+response.data.message);
                swal({
                    text:"Failed register \n"+response.data.message,
                    icon: "error",
                    closeOnClickOutside:false
                })
            }
        })
        .catch((err)=>{
            //alert(`Some error happen: ${err}`)  ;  
            swal({
                text:`Some error happen: ${err}`,
                icon: "error",
                closeOnClickOutside:false
            })
        })
    }

    if(isLoading){
        return (
           <div style={{fontSize:30,backgroundColor:'grey'}}>Loading...</div>
        )
     }

     
    if(localStorage.getItem("username")){
   
        return (
            <div style={{fontSize:30,backgroundColor:'grey'}}>You have already Logged in, refresh the page if the app does not load correctly.</div>
         )
    }
    return(
        <>
        <h1>Register Your Account </h1>
        <form onSubmit={handleSubmit(mySubmitHandler)}>
            <label className='login-label'>Username:</label>
            <input type="text" placeholder="Username here "   {...register("username")}/>
            <p style={{color:"red"}}>{errors.username?.message}</p>


            <label className='login-label'>Email Address:</label>
            <input type="email" placeholder="Email address here " onInvalid={e => e.target.setCustomValidity('Please enter valid email')} onInput={e => e.target.setCustomValidity('')}  {...register("email")}/>
            <p style={{color:"red"}}>{errors.email?.message}</p>

            <label className='login-label'>Password: (at least 8 characters)</label>
            <input type="password" placeholder="Password here "  {...register("password")}/>
            <p style={{color:"red"}}>{errors.password?.message}</p>
            <label className='login-label'>Confirmed Password:</label>
            <input type="password" placeholder="Confirmed Password here"  {...register("confirmedPassword")}/>
            <p style={{color:"red"}}>{errors.confirmedPassword?.message}</p>

            <input type="submit" value="Register now!" style={{marginBottom:"20px"}}/>

        </form>
        <Link to="/login">Already have an account? Login here</Link>
        </>
    )
}